<template>
  <div class="root_cartt">
      <span v-if="shoppingCartItems.length!=0 && !price" class="loader"></span>
    <EmptyCart v-if="!price && shoppingCartItems.length == 0" />
    <div v-else class='root_cart'>
      <div class="title_cart">Shopping Cart</div>
      <div class="xyzzz" v-if="shoppingCartItems">
        <div class="item_cart" v-for="n in shoppingCartItems" :key="n.id">
          <CartItemVue :obj="n" @reload="reloadCart" />
        </div>
      </div>
    </div>
    <!-- footer -->
    <div v-if="price" class="cart_footer">
      <div class="price_footer">
        Total - <span class="bx"> ₹ {{ price }}</span>
      </div>
      <div class="bttn">
        <b-button class="bttn2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="onClick('store')">
          CONTINUE SHOPPING
        </b-button>
        <b-button class="bttn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="onClick('storepayment')" :disabled="shoppingCartItems === null">
          CHECK OUT
        </b-button>
      </div>
    </div>
    
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText, BSpinner } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import CartItemVue from "./CartItem.vue";
import EmptyCart from "./EmptyCart.vue";

export default {
  components: {
    CartItemVue,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    EmptyCart
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  methods: {
    onClick(x) {
      this.$router.push({ name: x });
    },
    ...mapActions({
      fetchShoppingCartItems: "storeShoppingCartItems/setShoppingCartItems",
      calculateCost: "storeShoppingCartItems/calculateCost",
    }),
    async reloadCart() {
      const userId = this.currUser ? this.currUser.id : null;
      await this.fetchShoppingCartItems({ userId });
      this.calculateCost();
    },
  },
  async mounted() {
    // await this.fetchShoppingCartItems();
    // this.calculateCost();
    await this.reloadCart();
  },
  computed: mapState({
    currUser(state) {
      return state.login.currUser;
    },
    shoppingCartItems(state) {
      return state.storeShoppingCartItems.shoppingCartItems;
    },
    price(state) {
      return state.storeShoppingCartItems.finalPrice;
    },
  }),
  watch: {
    // is it correct?
    // shoppingCartItems(newItems, oldItems) {
    //   // this.fetchShoppingCartItems();
    // },
  },
};
</script>
<style scoped>
.bttn1 {
  background: #e87613;
  color: #ffffff;
  width: 185px;
  height: 40px;
}

.bttn2 {
  border: 2px solid #e87613;
  background: transparent;
  color: #e87613;
  width: 185px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
}

.bttn {
  display: flex;
  gap: 2rem;
}

.bx {
  font-weight: 700;
}

.title_cart {
  font-weight: 500;
  font-size: 28px;
  color: #414141;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.root_cart {
  margin: 0 8rem;
  margin-bottom: 6rem;
}

.cart_footer {
  position: fixed;
  width: 1440px;
  height: 95px;
  bottom: 0;
  background: #f8f8f8;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8rem;
}

.price_footer {
  font-weight: 500;
  font-size: 30px;
  color: #414141;
}
/* .loader {
  display: flex;
  align-items: center;
  justify-content: center;
} */
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  width: 48px;
  height: 48px;
  border: 5px solid #4385f5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.no_cart{
  opacity: 0.3 !important;
}

/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
  .bttn2 {
    display: none;
  }

  .price_footer {
    font-size: 19px;
  }

  .cart_footer {
    height: 95px;
    bottom: 0;
    background: #f8f8f8;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    flex-wrap: wrap;
    /* flex-direction: row-reverse; */
  }

  .root_cart {
    margin: 0 1rem;
    margin-bottom: 6rem;
  }
}
</style>
    